import React from 'react';
import '@fontsource/vt323';

const Jobs= () => {

  return (
    <>
    <div className="jobs">
      <div className="jobs-header">
        <a href="/">
        <div className="pixel-logo"></div>
        </a>
      </div>
      <div className="container">
        <div className="pixel-container">
          <div className="pixel-container-corners"></div>
          <div className="pixel-container-lines-y"></div>
          <div className="pixel-container-lines-x"></div>
          <div className="pixel-container-bg"></div>
          <div className="pixel-container-inner">
            <div className="jobs-top">
              <div className="jobs-top-inner">
                <div className="chat-top">
                  <div className="pixel-chat pixel-chat-1">
                    <div className="pixel-chat-corners"></div>
                    <div className="pixel-chat-lines-y"></div>
                    <div className="pixel-chat-lines-x"></div>
                    <div className="pixel-chat-bg"></div>
                    <div className="pixel-chat-arrow-bot"></div>
                    <div className="pixel-chat-inner">
                      <p>Hola! Desde easytechgreen te estamos buscando!</p>
                    </div>
                  </div>
                </div>
                <div className="chart-wrapper">
                  <div className="chart"></div>
                </div>
                <div className="chat-right">
                  <div className="pixel-chat pixel-chat-2">
                    <div className="pixel-chat-corners"></div>
                    <div className="pixel-chat-lines-y"></div>
                    <div className="pixel-chat-lines-x"></div>
                    <div className="pixel-chat-bg"></div>
                    <div className="pixel-chat-arrow-left"></div>
                    <div className="pixel-chat-inner">
                      <p>Si querés trabajar con nosotros mandanos tu CV a info@easytechgreen.com
                        indicando en el asunto para qué puesto te postulas.
                        Ofrecemos la posibilidad de trabajar remoto y part-time, con horarios flexibles para
                        que no afecte tu cursada en el caso que estes estudiando y un trabajo en un buen
                        ambiente laboral.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pixel-window">
              <div className="pixel-window-corners"></div>
              <div className="pixel-window-lines-y"></div>
              <div className="pixel-window-lines-x"></div>
              <div className="pixel-window-bg"></div>
              <div className="pixel-window-title">Coder Search Console</div>
              <div className="pixel-window-inner">
                <pre>
                  <br/>
                  &nbsp;     _ ____    ____ <br/>
                  &nbsp;    | / ___|  |  _ \  _____   __ <br/>
                  &nbsp; _  | \___ \  | | | |/ _ \ \ / / <br/>
                  &nbsp;| |_| |___) | | |_| |  __/\ V /  <br/>
                  &nbsp; \___/|____/  |____/ \___| \_/  #JSDev <br/>
                  <br/>
                </pre>
                <p>Si sos estudiante de Ingeniería Informática, computador científico o algo por el estilo,
                  con por lo menos el 2 año de la carrera terminado </p>
                <p>¡Este aviso es para vos!</p>

                <h2>¿Qué estamos buscando?</h2>
                <p>Una persona que le guste trabajar en equipo,  flexible y que trabaje con metodologías ágiles</p>

                <h2>Requisitos esperables:</h2>
                <p>Experiencia/conocimientos en alguna de las siguientes tecnologías:</p>
                <pre>
                  &nbsp;  * #JS: React / NextJS / Angular / Vue / Svelte (alguno de estos) <br/>
                  &nbsp;  * #CSS <br/>
                  &nbsp;  * #HTML <br/>
                  &nbsp;  * #Git <br/>
                  &nbsp;  * #Drupal siempre suma :) <br/>
                  <br/>
                </pre>
                <p>
                  Ofrecemos
                  <ul>
                    <li>🚀 El mejor ambiente de trabajo </li>
                    <li>✨ Horario flexible</li>
                    <li>🖥 Computadora </li>
                    <li>🤖 Capacitaciones</li>
                    <li>🌴 Trabajá donde quiereas (100% en la oficina / híbrido/ full remoto)</li>
                    <li>🥳 Día de cumpleaños libre </li>
                    <li>🍿 Snacks 🧃(para 🍩🍬 los remotos también)🍌</li>
                  </ul>
                </p>
                <p>Si te interesa mandanos un CV a info@easytechgreen.com asunto #JSDev</p>

                <pre>
                  <br/>
                  &nbsp;                        ##          ##  <br/>
                  &nbsp;                          ##      ##         <br/>
                  &nbsp;                        ############## <br/>
                  &nbsp;                      ####  ######  #### <br/>
                  &nbsp;                    ###################### <br/>
                  &nbsp;                    ##  ##############  ##   <br/>
                  &nbsp;                    ##  ##          ##  ##<br/>
                  &nbsp;                          ####  ####<br/>
                  <br/>
                  &nbsp; ____  _   _ ____    ____ <br/>
                  &nbsp;|  _ \| | | |  _ \  |  _ \  _____   __ <br/>
                  &nbsp;| |_) | |_| | |_) | | | | |/ _ \ \ / / <br/>
                  &nbsp;|  __/|  _  |  __/  | |_| |  __/\ V /  <br/>
                  &nbsp;|_|   |_| |_|_|     |____/ \___| \_/   #PHPDev<br/>
                  <br/>
                </pre>
                <p>Si tenés experiencia mínima de dos años codeando en PHP,
                  vivís en Argentina, ¡Este aviso es para vos!
                </p>
                <h2>¿Qué estamos buscando?</h2>
                <p>Una persona que le guste trabajar en equipo,  flexible y
                con buena esperiancia codeando en PHP.</p>

                <h2>Requisitos esperables:</h2>
                <p>Experiencia en alguno de los siguientes #Frameworks:</p>
                <pre>
                &nbsp;  * Drupal <br/>
                &nbsp;  * Symfony<br/>
                &nbsp;  * Laravel<br/>
                &nbsp;  * Cake<br/>
                </pre>
                <p>Otros conocimientos que nos gustaría que tengas son:</p>
                <pre>
                &nbsp;  * #Composer<br/>
                &nbsp;  * #MySQL<br/>
                &nbsp;  * #GO<br/>
                &nbsp;  * #JS<br/>
                &nbsp;  * #CSS<br/>
                &nbsp;  * #Docker<br/>
                &nbsp;  * #Git<br/>
                <br/>
                </pre>
                <p>El trabajo puede ser remoto o en nuestras oficinas. Nuestro ambiente
                  de trabajo es agradable y relajado y algunos de los beneficios que ofrecemos
                  son: computadora, horarios flexibles, día de cumpleaños libre, cajita de snacks mensual
                  y capacitación constante.
                </p>
                <p>Si te interesa mandanos un CV a info@easytechgreen.com asunto #PHPDev</p>
                <h3>
                ¡Te esperamos!
                </h3>

              </div>

              <div className="pixel-window-inner-corners"></div>
              <div className="pixel-window-inner-lines-y"></div>
              <div className="pixel-window-inner-lines-x"></div>
              <div className="pixel-window-inner-bg"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

Jobs.propTypes = {
};

export default Jobs;
